import React from "react";
import clsx from "clsx";
import { Container, Text } from "@atoms";

const ContainedGrid = ({ bgColor, copy, items, className: _className }) => {
  return (
    <section
      className={clsx(
        "relative py-16",
        {
          "bg-midnight": bgColor === "midnight",
        },
        _className
      )}
    >
      <Container className="relative z-20">
        <div className="flex flex-col items-center">
          <div className="mx-auto max-w-3xl">
            <Text
              richText
              className={clsx(
                "text-center prose-p:text-lg lg:prose-p:text-xl",
                {
                  "prose-dark": bgColor === "midnight",
                }
              )}
            >
              {copy}
            </Text>
          </div>
          <div className="mt-8 grid w-full grid-cols-1 gap-8 md:mt-12 md:grid-cols-2 lg:grid-cols-3">
            {items?.map(item => {
              return (
                <div key={item.uid}>
                  <Text
                    variant="h6"
                    className={clsx("mt-4", {
                      "text-mint": bgColor === "midnight",
                    })}
                  >
                    {item.heading}
                  </Text>
                  <Text
                    variant="sm"
                    className={clsx("mt-2", {
                      "text-white": bgColor === "midnight",
                    })}
                  >
                    {item.descriptor}
                  </Text>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </section>
  );
};

ContainedGrid.defaultProps = {
  overlap: false,
};

export default ContainedGrid;
